import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Wave from '../components/accessory/social/wave.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Contact Me`}</h1>
    <p>{`You can get in contact with me by typing really loudly... BER! BER! BERRRRRRRRR!`}</p>
    <h2>{`Or you can just wave to me.`}</h2>
    <p>{`Just click this button to simulate a friendly wave. 🤗`}</p>
    <Wave mdxType="Wave" />
    <p>{`Please note that I may not see you wave. 😆`}</p>
    <p>{`(This is an MDX filetype prototype)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      